
































































































































import _ from 'lodash';
import Vue from 'vue';
// @ts-ignore
import vueQr from 'vue-qr';
import { ResponseCode } from '~/util/constant';

export default Vue.extend({
  name: 'PayDeposit',
  components: {
    vueQr,
  },
  props: {
    detail: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dialogVisible: false,
      payType: 1, // 单日票 1  展期票 2
      num: 1,
      isaliPay: true,
      code: {},
      freeCode: {},
      logo: require('@/assets/icon/ali.png'),
      timer: undefined,
      ticketPrice: undefined,
    };
  },
  watch: {
    dialogVisible(newData) {
      if (newData) {
        this.getTicketPrice();
        this.getQRcode(1).then((code) => {
          this.code = code;
        });
        if (this.code) {
          const timer: any = setInterval(() => {
            this.getPayStatus();
          }, 1000);
          this.timer = timer;
        }
      }
    },
  },
  created() {},
  methods: {
    setPayType(type: number) {
      this.payType = type;
      if (this.isaliPay) {
        this.getQRcode(1).then((code) => {
          this.code = code;
          this.logo = require('@/assets/icon/ali.png');
        });
      } else {
        this.getQRcode(2).then((code) => {
          this.code = code;
          this.logo = require('@/assets/icon/wei.png');
        });
      }
    },
    handleClose() {
      this.dialogVisible = false;
      clearInterval(this.timer);
    },
    // 获取门票价格
    getTicketPrice() {
      this.$axios
        .get(`/api/ticket/query/${this.detail.exhibitionId}/${this.payType}`)
        .then((res: any) => {
          if (res.code === ResponseCode.SUCCESS) {
            this.ticketPrice = res.data.price;
          }
        });
    },

    // 切换支付方式
    toggleType(data: boolean) {
      this.isaliPay = data;
      if (this.isaliPay) {
        this.getQRcode(1).then((code) => {
          this.code = code;
          this.logo = require('@/assets/icon/ali.png');
        });
      } else {
        this.getQRcode(2).then((code) => {
          this.code = code;
          this.logo = require('@/assets/icon/wei.png');
        });
      }
    },
    // 购买数量减
    numLess() {
      if (this.num >= 2) {
        this.num -= 1;
        if (this.isaliPay) {
          this.getQRcode(1).then((code) => {
            this.code = code;
            this.logo = require('@/assets/icon/ali.png');
          });
        } else {
          this.getQRcode(2).then((code) => {
            this.code = code;
            this.logo = require('@/assets/icon/wei.png');
          });
        }
      }
    },
    // 购买数量加
    numPlus() {
      this.num += 1;
      if (this.isaliPay) {
        this.getQRcode(1).then((code) => {
          this.code = code;
          this.logo = require('@/assets/icon/ali.png');
        });
      } else {
        this.getQRcode(2).then((code) => {
          this.code = code;
          this.logo = require('@/assets/icon/wei.png');
        });
      }
    },
    // 获取二维码
    async getQRcode(type: number) {
      const { exhibitionId, exhibitionName } = this.detail;
      const res: any = await this.$axios.post('/api/pay/createPreOrder', {
        exhibitionId,
        exhibitionName,
        num: this.num,
        payType: type,
        price: this.ticketPrice,
        ticketType: this.payType,
        type: 1, // 门票
      });
      return res.data;
    },
    async getPayStatus() {
      const res: any = await this.$axios.get(
        `/api/pay/queryOrderStatus/${this.detail.exhibitionId}/1`
      );
      if (res.code === ResponseCode.SUCCESS) {
        if (_.get(res, 'data.status', null) === 2) {
          this.handleClose();
          // @ts-ignore
          this.$parent.showOrderInfo(res.data);
        }
      }
    },
  },
});
