























































































































import Vue from 'vue';
import { ElForm } from 'element-ui/types/form';
import UUID from 'uuid';
export default Vue.extend({
  name: 'OrderInfo',
  props: {
    detail: {
      type: Object,
    },
  },

  data(): any {
    const visitors: any[] = [];
    return {
      orderData: {},
      dialogVisible: false,
      ruleForm: {
        company: '1',
        tel: '1',
        phone: '1',
        user: '1',
        email: '1',
        userEmail: '1',
        visitors,
      },
      rules: {
        company: [
          { required: true, message: '公司名称不能为空', trigger: 'blur' },
        ],
        user: [{ required: true, message: '联系人不能为空', trigger: 'blur' }],
        email: [{ required: true, message: '邮箱不能为空', trigger: 'blur' }],
        tel: [{ required: true, message: '公司电话不能为空', trigger: 'blur' }],
        phone: [
          { required: true, message: '联系电话不能为空', trigger: 'blur' },
        ],
        userEmail: [
          { required: true, message: '联系人邮箱不能为空', trigger: 'blur' },
        ],
        visitorIdentity: [
          { required: true, message: '护照号不能为空', trigger: 'blur' },
        ],
        visitorNameEn: [
          { required: true, message: '姓名拼音不能为空', trigger: 'blur' },
        ],
        visitorName: [
          { required: true, message: '人员姓名不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  watch: {
    dialogVisible(data) {
      this.ruleForm.visitors = [];
      if (data) {
        [...Array(this.orderData.num).keys()].forEach(() => {
          this.ruleForm.visitors.push({
            key: UUID.v4(),
            value: { visitorIdentity: '', visitorName: '', visitorNameEn: '' },
          });
        });
      }
    },
  },
  created(): void {},
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    handleSubmit() {
      const $orderForm = this.$refs.orderform as ElForm;
      $orderForm.validate(async (valid) => {
        if (valid) {
          const { company, tel, phone, user, email, userEmail } = this.ruleForm;
          const visitors: any = [];
          this.ruleForm.visitors.forEach((item: any) => {
            visitors.push({
              ...item.value,
            });
          });
          const res: any = await this.$axios.post(
            'api/pay/prefectTicketOrder',
            {
              contactMail: userEmail,
              contactName: user,
              contactTel: phone,
              corporationMail: email,
              corporationName: company,
              corporationTel: tel,
              orderNo: this.orderData.orderNo,
              visitors,
            }
          );
          if (res.code === '1') {
            this.$message.success('提交成功！');
            this.dialogVisible = false;
          } else {
            this.$message.error('提交失败！');
          }
        }
      });
    },
  },
});
