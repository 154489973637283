


























































import Vue from 'vue';

export default Vue.extend({
  name: 'Invoice',
  data() {
    return {
      isKp: false,
      invoiceRuleForm: {},
      invoiceRules: {
        invoiceETitle: [
          { required: true, message: '发票抬头不能为空', trigger: 'blur' },
        ],
        invoiceETaxpayer: [
          { required: true, message: '公司税号不能为空', trigger: 'blur' },
        ],
        email: [
          { required: true, message: '电子邮箱不能为空', trigger: 'blur' },
          { type: 'email', message: '输入正确的电子邮箱地址', trigger: 'blur' },
        ],
      },
    };
  },
});
