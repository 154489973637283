
























































import moment from 'moment';
import Vue from 'vue';
import { handleNewsUrl } from '@/util/common';
export default Vue.extend({
  name: 'NewsExh',
  filters: {
    dateFormat(date: string) {
      return moment(new Date(date)).format('YYYY-MM-DD HH:mm');
    },
    // description(item: Record<string, any>): any {
    //   if (item.description) {
    //     return item.description;
    //   } else {
    //     const reg = /<[^>]+>/gi;
    //     const description = item.content
    //       .replace(reg, '')
    //       .replace(/&nbsp;/g, '')
    //       .trim();
    //     return description.slice(0, 100) + '...';
    //   }
    // },
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    more: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'news',
    },
  },
  methods: {
    handleNewsUrl,
  },
});
