























































































































































import moment from 'moment';
import Vue from 'vue';
import _ from 'lodash';

export default Vue.extend({
  name: 'NewsExh',
  filters: {
    dateFormat(date: string) {
      return moment(new Date(date)).format('YYYY-MM-DD HH:mm');
    },
  },
  props: {
    detail: {
      // detail.pavilionInfo.picture
      type: Object,
      default: () => {},
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    // 展馆图片
    exhibitionHallList() {
      let str = this.detail.pavilionInfo.picture;
      let list = str.split(';');
      return list && list.length > 0 ? list : [];
    },
  },
  watch: {
    list(e) {
      this.sceneImageList = this.handleGroup(e, 2);
      e.map((item: any) => {
        if (item.resourceUrl) {
          item['picList'] = item.resourceUrl.split(',');
          if (item.resourceType == 1) this.boothImageList.push(item);
          if (item.resourceType == 2) {
            // this.sceneImageList.push(item)
            // this.handleGroup()
          }
          // if (item.resourceType == 3) this.exhibitionHallList.push(item)
          if (item.resourceType == 4) this.videoList.push(item);
        }
      });
    },
  },
  data(): any {
    return {
      firstIndex: 0,
      activeName: 'first',
      sceneImageList: [], // 现场图片
      boothImageList: [],
      videoList: [],
      activeIndex: 0,
    };
  },
  methods: {
    handleFirstTab(i: number) {
      this.firstIndex = i;
    },
    handleTab(i: number) {
      this.activeIndex = i;
    },
    handleGroup(list: any, type: number) {
      let res = list.filter((item: any) => item.resourceType == type);

      res.map((item: any) => {
        item['picList'] = item.resourceUrl.split(',');
      });

      let result = res.sort(
        (a: any, b: any) => b.resourceYear - a.resourceYear
      );
      result.push(result.shift());

      return result;
    },
  },
});
